import axios from 'axios';
import { useErrorStore } from '@/stores/error';
import { getToken } from '@/utils/auth';

const instance = axios.create({
  baseURL: 'http://localhost:8080',
});

instance.interceptors.request.use((config) => {
  const token = getToken();
  const newConfig = { ...config };

  if (token) newConfig.headers = { ...newConfig.headers, Authorization: `Bearer ${token}` };

  return newConfig;
});

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    const { setError } = useErrorStore();
    setError(error);
  }
);

export default instance;
