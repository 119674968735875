import axios from '@/plugins/axios';

export const fetchProfile = async (id = '') => {
  const params = id ? `?id=${id}` : '';
  const response = await axios.get(`/api/getProfile${params}`);

  const profile = response?.data.response;

  return profile;
};

export const fetchProfileStatistic = async (id = '') => {
  const params = id ? `?id=${id}` : '';
  const response = await axios.get(`/api/getProfileStats${params}`);

  const statistic = response?.data.response;

  return statistic;
};

export const fetchFullProfile = async (id = '') => {
  const requests = [fetchProfile(id), fetchProfileStatistic(id)];
  const responses = await Promise.all(requests);

  const [profile, statistic] = responses;

  return {
    ...profile,
    statistic,
  };
};

export const fetchProfileStrongThemes = async (id = '') => {
  const params = id ? `?id=${id}` : '';
  const response = await axios.get(`/api/getProfileStrongThemes${params}`);

  return response?.data.response;
};

export const fetchProfileLikedThemes = async (id = '') => {
  const params = id ? `?id=${id}` : '';
  const response = await axios.get(`/api/getProfileLikedThemes${params}`);

  return response?.data.response;
};

export const editProfileName = async (params) => {
  const response = await axios.post('/api/profile/edit/name', params);
  return response?.data.response;
};

export const editProfileEmail = async (params) => {
  const response = await axios.post('/api/profile/edit/mail', params);
  return response?.data.response;
};

export const editProfilePhone = async (params) => {
  const response = await axios.post('/api/profile/edit/phone', params);
  return response?.data.response;
};

export const editProfileAvatar = async (params) => {
  const response = await axios.post('/api/profile/edit/avatar', params);
  return response?.data.response;
};

export const fetchLeaderboard = async () => {
  const response = await axios.get('/api/leaderboard');

  const { leaderboard = [], user = {} } = response?.data.response || {};

  return {
    leaderboard,
    user,
  };
};
