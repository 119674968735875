<template>
  <v-dialog
    v-model="isActive"
    :scrim="false"
    fullscreen
    class="bg-background"
  >
    <div class="wrapper">
      <v-container>
        <template v-if="opponent">
          <app-card class="mb-3">
            <template #img>
              <v-img
                width="76"
                height="76"
                :src="require(`@/assets/images/avatars/avatar-${opponent.avatar}.png`)"
              />
            </template>
            <template #title>
              <span class="font-weight-bold text-h6">{{ opponent.name }}</span>
            </template>
            <template #subtitle>
              <span class="text-body-2 text-secondary-text"
                >{{ opponent.statistic.rank || 'Новичок' }} ({{ opponent.statistic.points || 0 }})</span
              >
            </template>
          </app-card>
          <player-statistic
            v-if="opponent.statistic"
            :statistic="opponent.statistic"
          />
        </template>
      </v-container>

      <v-container class="themes bg-white">
        <div>
          <div class="d-flex justify-space-between align-center mb-4">
            <h2 class="text-h5 font-weight-bold text-headlines-text">Тема</h2>
            <v-icon
              icon="mdi-close"
              class="pointer"
              @click="onClose"
            />
          </div>

          <app-search v-model="search" />
        </div>

        <div class="list">
          <v-infinite-scroll
            empty-text=""
            color="primary"
            @load="loadThemes"
            class="overflow-y-hidden"
          >
            <div>
              <template
                v-for="item in themes"
                :key="item.id"
              >
                <div
                  class="list-item pointer"
                  @click="onChangeTheme(item.id)"
                >
                  <div
                    :class="{ 'font-weight-bold': item.id === theme?.id }"
                    class="d-flex justify-space-between align-center text-h6 text-basic-text py-3"
                  >
                    {{ item.name }}
                    <v-icon
                      v-if="item.id === theme?.id"
                      size="small"
                      color="accent-light"
                      icon="mdi-check-circle"
                    />
                  </div>
                </div>
              </template>
            </div>
          </v-infinite-scroll>

          <div
            v-show="theme?.id"
            :class="{ 'd-flex': theme?.id }"
            class="start-game-outer justify-center align-center bg-white"
          >
            <div class="start-game-inner w-100">
              <v-btn-primary
                width="100%"
                max-width="624"
                class="start-game-button"
                @click="onStart"
              >
                {{ isNewGame ? 'Отправить вызов' : 'Выбрать тему' }}
              </v-btn-primary>
            </div>
          </div>
        </div>
      </v-container>
    </div>
  </v-dialog>
</template>

<script setup>
import { ref, computed, watch, defineEmits } from 'vue';
import { storeToRefs } from 'pinia';
import { useGameStore } from '@/stores/game';
import { fetchThemes } from '@/api/game';
import debounce from 'lodash.debounce';

import { VInfiniteScroll } from 'vuetify/labs/VInfiniteScroll';
import AppCard from '@/components/AppCard.vue';
import AppSearch from '@/components/AppSearch.vue';
import PlayerStatistic from '@/components/PlayerStatistic.vue';

const emit = defineEmits(['start-game', 'start-round']);

const gameStore = useGameStore();
const { game, opponent, theme, isActiveThemeSelection, themes, isExistsMoreThemes, fetchOptionsThemes } =
  storeToRefs(gameStore);

const { resetSelectTheme } = gameStore;

const search = ref('');

const isActive = computed({
  get() {
    return isActiveThemeSelection.value;
  },
  set(value) {
    isActiveThemeSelection.value = value;
  },
});

const isNewGame = computed(() => !game.value);

watch(isActiveThemeSelection, () => {
  if (isActiveThemeSelection.value) {
    isExistsMoreThemes.value = true;

    getThemes();
    return;
  }

  resetSelectTheme();
});

watch(
  search,
  debounce(() => {
    fetchOptionsThemes.value.offset = 0;
    fetchOptionsThemes.value.name = search;

    getThemes();
  }, 400)
);

const getThemes = async () => {
  const { themes: fetchedThemes, existsMore } = await fetchThemes(fetchOptionsThemes.value);

  themes.value = fetchedThemes;
  isExistsMoreThemes.value = existsMore;
};

const loadThemes = async ({ done }) => {
  if (!isExistsMoreThemes.value) {
    done('empty');
    return;
  }

  fetchOptionsThemes.value.offset = fetchOptionsThemes.value.offset + fetchOptionsThemes.value.limit;

  const { themes: fetchedThemes, existsMore } = await fetchThemes(fetchOptionsThemes.value);

  themes.value = {
    ...themes.value,
    ...fetchedThemes,
  };

  existsMore ? done('ok') : done('empty');

  isExistsMoreThemes.value = existsMore;
};

const onChangeTheme = (id) => {
  theme.value = {
    id,
    name: themes.value[id].name,
  };
};

const onStart = () => {
  isNewGame.value ? emit('start-game') : emit('start-round');
};

const onClose = () => {
  isActive.value = false;
};
</script>

<style lang="scss" scoped>
@use '@/assets/styles/list.scss';

.wrapper {
  display: grid;
  grid-template: 216px auto / 1fr;
}

.themes {
  display: grid;
  grid-template: 98px auto / 1fr;
  border-radius: 16px 16px 0 0;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
}

.start-game {
  &-outer {
    position: sticky;
    bottom: 0;
    z-index: 100;
    height: 60px;
  }

  &-inner {
    height: 44px;
    position: sticky;
    bottom: 0;
  }

  &-button {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
  }
}
</style>
