import { createRouter, createWebHistory } from 'vue-router';
import { getToken } from '@/utils/auth';

import DefaultLayout from '@/layouts/DefaultLayout';
import MainLayout from '@/layouts/MainLayout';

const routes = [
  {
    path: '/',
    name: 'games',
    meta: { requiresAuth: true, layout: MainLayout },
    component: () => import(/* webpackChunkName: "games" */ '@/views/GamesView'),
  },
  {
    path: '/login',
    name: 'login',
    meta: { layout: DefaultLayout },
    component: () => import(/* webpackChunkName: "login" */ '@/views/LoginView'),
  },
  {
    path: '/registration',
    name: 'registration',
    meta: { layout: DefaultLayout },
    component: () => import(/* webpackChunkName: "registration" */ '@/views/RegistrationView'),
  },
  {
    path: '/onboarding',
    name: 'onboarding',
    meta: { requiresAuth: true, layout: DefaultLayout },
    component: () => import(/* webpackChunkName: "onboarding" */ '@/views/OnboardingView'),
  },
  {
    path: '/game/:id',
    name: 'game',
    meta: { requiresAuth: true, layout: DefaultLayout },
    component: () => import(/* webpackChunkName: "game" */ '@/views/GameView'),
  },
  {
    path: '/opponents',
    name: 'opponents',
    meta: { requiresAuth: true, layout: MainLayout },
    component: () => import(/* webpackChunkName: "opponents" */ '@/views/OpponentsView'),
  },
  {
    path: '/rating',
    name: 'rating',
    meta: { requiresAuth: true, layout: MainLayout },
    component: () => import(/* webpackChunkName: "rating" */ '@/views/RatingView'),
  },
  {
    path: '/profile/:id',
    name: 'profile',
    meta: { requiresAuth: true, layout: MainLayout },
    component: () => import(/* webpackChunkName: "profile" */ '@/views/ProfileView'),
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: 'login' },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to) => {
  const isRequiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const token = getToken();

  if (isRequiresAuth) {
    return token ? true : { name: 'login' };
  } else {
    return ['login', 'registration'].includes(to.name) && token ? { name: 'games' } : true;
  }
});

export default router;
